
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "dashboard-claim-summary",
  components: {},
  props: {
    claimsSummary: Object
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  }
});
